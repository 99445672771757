<template>
  <div class="agent-sales-report">
    <div class="container fluid">
      <div class="card flat d-flex align-items-center p-2 mb-1">
        <fd-button class="circle" @click="goBack">
          <i class="fas fa-chevron-left"></i>
        </fd-button>
        <h2 class="px-2">Agent Sales Report</h2>
      </div>

      <!-- ===================== Sales Type Selector ======================= -->
      <div class="card flat p-1 py-2 sm-p-3 mb-4 row justify-content-center">
        <div class="col-12 sm-col-6">
          <selector
            class="w-100"
            v-model="selectedType"
            :options="reportType"
          ></selector>
        </div>
      </div>
      <!-- ============================ Report ============================= -->
      <component :is="reportComponent" agent> </component>
    </div>
  </div>
</template>

<script>
import SubsalesReport from "@/components/SalesReport/SubsalesReport";
import RentSalesReport from "@/components/SalesReport/RentSalesReport";

const REPORT_TYPE = {
  SUBSALES: "Subsales",
  RENT: "Rent"
};

export default {
  components: {
    Selector: () => import("@/components/GlobalComponents/Selector"),
    AgentSaleTable: () => import("@/components/SalesReport/AgentSaleTable"),
    AgentKeyIndex: () => import("@/components/SalesReport/AgentKeyIndex")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      selectedType: REPORT_TYPE.SUBSALES,
      reportType: this.$mapJsonToArray(REPORT_TYPE, (e) => e)
    };
  },
  computed: {
    reportComponent() {
      switch (this.selectedType) {
        case REPORT_TYPE.SUBSALES:
          return SubsalesReport;
        case REPORT_TYPE.RENT:
          return RentSalesReport;
        default:
          return "";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    goBack() {
      this.$router.replace({
        name: "BranchSalesReport",
        params: {
          id: this.$route.params.branchId
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
